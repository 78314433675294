import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { ErrorModalContext } from '../../../../Modals/ErrorModal';
import ErrorModal from '../../../../Modals/ErrorModal.jsx';

import SearchContext from '../../SearchContext';

import RevealCategory from '../RevealCategory';
import Spinner from '../../../../shared/Loader/Spinner';

import { retrieveFilterCategoryValues } from '../../../../backend/filter.js';

const SpinnerContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const StyledForm = styled.form`
  font-family: Univers;
  font-size: 14px;
  margin: 20px;
  max-height: 500px;
  overflow-y: auto;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  transition: color 0.2s ease-out;
  &:hover {
    color: var(--color-primary);
  }
`;

const StyledCheckbox = styled.input`
  flex-shrink: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: 2px solid var(--color-primary);
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  background-color: var(--color-gray5);
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: ${(props) => !props.$isSelected && 'var(--color-gray4)'};
  }

  ${(props) =>
    props.$isSelected &&
    css`
      background: #c00 no-repeat;
      position: relative;
      background-image: url("data:image/svg+xml,%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Input' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Input/_bits/Checkboxes/Selected' transform='translate(-4.000000, -6.000000)' fill='%23FFFFFF'%3E%3Cpath d='M19,6 C19.5522847,6 20,6.44771525 20,7 C20,7.26988828 19.8930837,7.51480492 19.7192955,7.69470556 L19.7222635,7.69789588 L10.1647057,17.2345575 C9.98414772,17.3860083 9.75305033,17.48 9.5,17.48 C9.25127943,17.48 9.02376699,17.3891972 8.84880452,17.2389335 L8.84561816,17.2419518 L4.24217555,12.6580659 C4.09256762,12.4806317 4,12.251126 4,12 C4,11.4477153 4.44771525,11 5,11 C5.25064491,11 5.47975225,11.0922133 5.65524705,11.2445649 L5.65638911,11.2438523 L5.69084751,11.2769973 C5.70148432,11.2871639 5.71189715,11.2975629 5.72207784,11.3081861 L9.5,15.071 L18.2367236,6.35389552 C18.3965056,6.16532551 18.6249121,6.03674825 18.8833789,6.00672773 Z' id='checkmark'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-position: center 3px;
    `}
`;

const DynamicallyGeneratedFilterCategory = ({
  filterCategoryName,
  classPath,
  searchTerm,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isRowExpanded, setIsRowExpanded] = useState(false);
  const [filterValues, setFilterValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [, setError] = useContext(ErrorModalContext);

  const { isAuthModalOpen, onChangeDynamicFilterValue } =
    useContext(SearchContext);

  const {
    mutate: mutateFilterCategoryValues,
    isLoading: isLoadingFilterCategoryValues,
  } = useMutation(
    (data) =>
      retrieveFilterCategoryValues(
        filterCategoryName.toUpperCase(),
        classPath,
        searchTerm
      ),
    {
      onSuccess: (responseData) => {
        setFilterValues(responseData);
      },
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] === '401') {
          isAuthModalOpen(true);
        } else {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#FV-2-${errorCode[1]}`,
          });
        }
      },
    }
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramValues = searchParams.get(filterCategoryName);

    if (paramValues) {
      setSelectedValues(paramValues.split(','));
    } else {
      setSelectedValues([]);
    }
  }, [location.search, filterCategoryName]);

  const handleRowExpanded = () => {
    setIsRowExpanded((prev) => !prev);
    mutateFilterCategoryValues();
  };

  const handleCheckboxChange = (name, value) => {
    const searchParams = new URLSearchParams(location.search);
    const currentValues = searchParams.get(name)?.split(',') || [];

    let updatedValues;

    if (currentValues.includes(value)) {
      updatedValues = currentValues.filter((v) => v !== value);
    } else {
      updatedValues = [...currentValues, value];
    }

    if (updatedValues.length > 0) {
      searchParams.set(name, updatedValues.join(','));
    } else {
      searchParams.delete(name);
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    onChangeDynamicFilterValue(name, updatedValues);
  };

  return (
    <>
      <ErrorModal />

      <React.Fragment key={filterCategoryName}>
        <RevealCategory
          name={filterCategoryName}
          onIsRowExpanded={() => handleRowExpanded()}
          filterCount={selectedValues?.length}
        />
        {isRowExpanded && isLoadingFilterCategoryValues && (
          <SpinnerContainer>
            <Spinner active={true} small={true} />
          </SpinnerContainer>
        )}
        {isRowExpanded && !isLoadingFilterCategoryValues && (
          <StyledForm>
            {filterValues ? (
              filterValues.map(({ Value, Count }, index) => (
                <CheckboxContainer key={index + Value + Count}>
                  <StyledCheckbox
                    id={`checkbox-${index + Value + Count}`}
                    type="checkbox"
                    checked={selectedValues.includes(Value || 'unspecified')}
                    $isSelected={selectedValues.includes(
                      Value || 'unspecified'
                    )}
                    onChange={() =>
                      handleCheckboxChange(
                        filterCategoryName,
                        Value || 'unspecified'
                      )
                    }
                  ></StyledCheckbox>
                  <CheckboxLabel
                    htmlFor={`checkbox-${index + Value + Count + 'Material'}`}
                  >
                    {Value || 'Value Not Specified'} {`[${Count}]`}
                  </CheckboxLabel>
                </CheckboxContainer>
              ))
            ) : (
              <div>none</div>
            )}
          </StyledForm>
        )}
      </React.Fragment>
    </>
  );
};

export default DynamicallyGeneratedFilterCategory;
