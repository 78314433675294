import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from 'react-query';

import { oktaAuth } from '../../../services/okta-service';
import DOMPurify from 'dompurify';
import styled from 'styled-components';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import SearchContext from '../SearchContext';

import Size from './FilterCategories/Size';
import DynamicallyGeneratedFilterCategory from './FilterCategories/DynamicallyGeneratedFilterCategory';

import Category from './Category';

import { retrieveDynamicFilterCategories } from '../../../backend/filter.js';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: fit-content;
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
`;

const SectionContainer = styled.div`
  font-family: Univers;
  background-color: #fff;
  padding: 12px;
  margin-right: 18px;
  margin-top: 18px;
  height: fit-content;
  // border-radius: 8px;
  @media (max-width: 1024px) {
    // border-radius: 0;
    margin-right: 0;
    margin-bottom: 18px;
    margin-top: 0;
  }
`;

const TitleAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  @media (max-width: 900px) {
    margin-top: 0;
    margin-left: 12px;
    margin-right: 12px;
  }
`;

const FiltersTitle = styled.p`
  font-size: 26px;
  font-weight: 500;
  color: var(--color-gray1);
  margin: 24px 0 8px;
  align-self: baseline;
  @media (max-width: 1024px) {
    margin: 18px 0 8px;
  }
`;

const DividerLine = styled.hr`
  border: none;
  height: 1px;
  background-color: black;
  margin-top: 15px;
  margin-bottom: 0;
`;

const ClearAllButton = styled.button`
  font-family: Univers;
  width: 100%;
  height: 38px;
  font-size: 14px;
  align-self: flex-end;
  background-color: ${(props) =>
    props.$isSelectionMade ? 'var(--color-primary)' : '#6D757A'};
  color: white;
  border: unset;
  // border-radius: 8px;
  margin-top: 16px;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-bottom-color 0.2s ease-out;

  cursor: ${(props) => (props.$isSelectionMade ? 'pointer' : '')};
  &:hover {
    background-color: ${(props) =>
      props.$isSelectionMade ? 'var(--color-dark-red)' : ''};
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const CloseButtonContainer = styled.div`
  height: 53px;
  width: 100%;
  background-color: var(--color-gray2);
  display: flex;
  align-items: center;
  justify-content: end;
`;

const CloseButton = styled.button`
  background: var(--color-gray2);
  border: 1px solid white;
  // border-radius: 12px;
  cursor: pointer;
  margin-right: 20px;
  color: white;
  font-family: Univers;
  font-size: 16px;
  height: 40px;
  width: 129px;
`;

const FilterPaneContainer = ({ isLoadingSearchWithinClasses }) => {
  const {
    showMobileFilterPane,
    sizeMinIdValue,
    sizeMaxIdValue,
    sizeMinOdValue,
    sizeMaxOdValue,
    sizeMinLengthValue,
    sizeMaxLengthValue,
    classPath,
    searchTerm,
    onClearAll,
    handleMobileFilterIconClick,
    dynamicFilterCategories,
  } = useContext(SearchContext);

  const [isHalliburtonUser, setIsHalliburtonUser] = useState(false);
  const [filterCategories, setFilterCategories] = useState([]);

  let cachedClasses = localStorage.getItem('fetchedClasses');
  cachedClasses = cachedClasses
    ? JSON.parse(DOMPurify.sanitize(cachedClasses))
    : null;

  const handleClearAll = () => {
    onClearAll();
  };

  useEffect(() => {
    const getEmail = async () => {
      const user = await oktaAuth.getUser();
      const email = user.email;
      if (email.includes('@halliburton.com')) {
        setIsHalliburtonUser(true);
      }
    };
    getEmail();
  }, []);

  const {
    mutate: mutateDynamicFilterCategories,
    isLoading: isLoadingFilterCategories,
  } = useMutation(
    (data) => retrieveDynamicFilterCategories(classPath, searchTerm),
    {
      onSuccess: (responseData) => {
        setFilterCategories(responseData);
      },
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] === '401') {
          isAuthModalOpen(true);
        } else {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#FC-2-${errorCode[1]}`,
          });
        }
      },
    }
  );

  useEffect(() => {
    mutateDynamicFilterCategories();
  }, [classPath, searchTerm]);

  const filterValueSelected =
    sizeMinIdValue.length ||
    sizeMaxIdValue.length ||
    sizeMinOdValue.length ||
    sizeMaxOdValue.length ||
    sizeMinLengthValue.length ||
    sizeMaxLengthValue.length ||
    dynamicFilterCategories.length;
  const sizeValues = ['MAX OD', 'MAX ID', 'MIN OD', 'MIN ID', 'LENGTH', 'SIZE'];

  const handleOnClose = () => {
    handleMobileFilterIconClick();
  };

  return (
    <MainContainer>
      {isLoadingSearchWithinClasses && (
        <SectionContainer>
          <Skeleton width={'100%'} height={200} />
        </SectionContainer>
      )}
      {!isLoadingSearchWithinClasses && cachedClasses && (
        <>
          {showMobileFilterPane && (
            <CloseButtonContainer>
              <CloseButton alt="close menu" onClick={() => handleOnClose()}>
                Close
              </CloseButton>
            </CloseButtonContainer>
          )}
          <SectionContainer>
            <Category isLoadingFilters={isLoadingFilterCategories} />
          </SectionContainer>
        </>
      )}

      <SectionContainer>
        {isLoadingFilterCategories && <Skeleton width={'100%'} height={300} />}
        {!showMobileFilterPane && !isLoadingFilterCategories && (
          <>
            <FiltersTitle>Filters</FiltersTitle>
            <Size />
            {filterCategories
              ?.filter(
                (filterCategoryName) =>
                  !sizeValues.includes(filterCategoryName) &&
                  (isHalliburtonUser || filterCategoryName !== 'PRODUCT TYPE')
              )
              .map((filterCategoryName) => (
                <DynamicallyGeneratedFilterCategory
                  key={filterCategoryName}
                  filterCategoryName={filterCategoryName}
                  classPath={classPath}
                  searchTerm={searchTerm}
                  dynamicFilterCategories={dynamicFilterCategories}
                />
              ))}

            <ClearAllButton
              $isSelectionMade={filterValueSelected}
              onClick={() => handleClearAll()}
            >
              Clear All
            </ClearAllButton>
          </>
        )}
        {showMobileFilterPane && (
          <>
            <>
              <TitleAndButtonContainer>
                <FiltersTitle>Filters</FiltersTitle>
                <ClearAllButton
                  $isSelectionMade={filterValueSelected}
                  onClick={() => handleClearAll()}
                >
                  Clear All
                </ClearAllButton>
              </TitleAndButtonContainer>
              <DividerLine />
            </>
            <>
              <Size />

              {filterCategories
                ?.filter(
                  (filterCategoryName) =>
                    !sizeValues.includes(filterCategoryName) &&
                    (isHalliburtonUser || filterCategoryName !== 'PRODUCT TYPE')
                )
                .map((filterCategoryName) => (
                  <DynamicallyGeneratedFilterCategory
                    key={filterCategoryName}
                    filterCategoryName={filterCategoryName}
                    classPath={classPath}
                    searchTerm={searchTerm}
                    dynamicFilterCategories={dynamicFilterCategories}
                  />
                ))}
            </>
          </>
        )}
      </SectionContainer>
    </MainContainer>
  );
};

export default FilterPaneContainer;
